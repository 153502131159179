import React, { FC, useEffect, useState } from 'react';
import { ImageProps } from '.';
import Image from 'next/image';

export const ImageTemplate: FC<ImageProps> = ({ className, url, fallbackUrl }) => {
    const [imageUrl, setImageUrl] = useState(url);

    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        setImageUrl(url);
    }, [url]);

    const handleError = (): void => {
        setHasError(true);
        setImageUrl(fallbackUrl);
    };

    if (hasError && !fallbackUrl) {
        return null;
    }

    return (
        <Image
            className={className}
            src={imageUrl}
            width={0}
            height={0}
            objectFit="contain"
            loader={({ src }: any) => src}
            onError={handleError}
        />
    );
};
