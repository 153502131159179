import React from 'react';
import { SC } from '../../styles/theme';
import { ImageStyled } from '././ImageStyled';

export interface ImageProps extends SC {
    fallbackUrl?: string;
    url?: string;
}

const Image = (props: ImageProps) => {
    return <ImageStyled {...props} />;
};

export default Image;
